// https://github.com/sudodoki/copy-to-clipboard/blob/main/index.js
import copyToClipboard from 'copy-to-clipboard';
import moment from 'moment';

import NotificationService from '@master/Services/NotificationService';

import { DATE_FORMAT_OPTIONS, FOLDER, USER_ROLE } from '@master/constants';

let round = n => {
  return Math.round(n * 100) / 100;
};
let clone = obj => {
  return JSON.parse(JSON.stringify(obj));
};

let getCookie = cname => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

/**
 * @param {*} cname - cookie name
 * @param {*} cvalue - cookie value
 * @param {*} exdays - days until expire, default 30
 */
function setCookie(cname, cvalue = '', exdays = 30, domain_root = false) {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  let cookie_string = cname + '=' + cvalue + ';' + expires + ';path=/';
  if (domain_root === true) {
    const host_pieces = /(\.|^)([^.]+)\.([^.:]+)(:\d+)?$/.exec(window.location.host);
    cookie_string += `;domain=.${host_pieces[2]}.${host_pieces[3]}`;
  }
  document.cookie = cookie_string;
}

/**
 * Will clear / set current name cookie to expire right away
 * @param {*} cname - cookie name
 */
function clearCookie(cname) {
  setCookie(cname, '', -1);
}

function downloadFile(url, filename) {
  let a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = url;
  a.download = filename;
  a.click();
  document.body.removeChild(a);
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject();
    }

    // big file get base 64, probably not needed since XHR cant handle that big request
    // let reader = new FileReader();
    // reader.onload = () => {

    //   let binary = '';
    //   const bytes = new Uint8Array(reader.result);
    //   const len = bytes.byteLength;
    //   for (let i = 0; i < len; i++) {
    //     binary += String.fromCharCode(bytes[i]);
    //   }
    //   callback('data:' + file.type + ';base64,' + btoa(binary));
    // };
    // reader.readAsArrayBuffer(file);

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = error => {
      reject(error);
    };
  });
}

function saveJSON(data = {}, filename = null) {
  if (filename == null) {
    filename = 'output';
  }
  filename += '.json';
  data = JSON.stringify(data);
  const blob = new Blob([data], { type: 'text/json' });
  downloadBlob(filename, blob);
}

function saveCSV(data = [], filename = null) {
  if (filename == null) {
    filename = 'output';
  }

  filename += '.csv';
  const blob = new Blob([data], { type: 'text/csv' });
  downloadBlob(filename, blob);
}

function s2ab(s) {
  let buf = new ArrayBuffer(s.length);
  let view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

function downloadBlob(filename, blob) {
  const url = window.URL.createObjectURL(blob);
  downloadFile(url, filename);
  window.URL.revokeObjectURL(url);
}

function getFileContents(file) {
  let output = file.b64;

  // legacy fallback with no alg key
  if (file.alg == null) {
    return atob(file.b64);
  }

  // reverse decode input file
  const algs = file.alg;
  for (let i = algs.length - 1; i >= 0; i--) {
    const alg = algs[i];
    if (alg === 'b64') {
      output = atob(output);
    } else if (alg === 'entity') {
      output = decodeHTML(output);
    }
  }
  return output;
}

function b64toBlob(b64, mime = null, size = 512) {
  // if given b64 string has a mime, get the mime from the string
  if (mime == null) {
    const split = b64.split(',');
    mime = split[0].split(':')[1].split(';')[0];
    b64 = split[1];
  }

  const byteCharacters = atob(b64);

  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += size) {
    const slice = byteCharacters.slice(offset, offset + size);
    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: mime });
}

function decodeHTML(html) {
  let txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

function unique(array) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

function checkForLocale(group = null) {
  const locale = navigator.language;
  const language_switch_enabled = getCookie('nxd-translate') === 'true';
  const is_jpn = locale === 'ja-JP' || (group != null && group.country === 'JP');
  // leaving in for testing
  // setCookie('nxd-translate', true);
  // clearCookie('nxd-translate');
  // clearCookie('googtrans');
  // return;

  // MVP for jap. clients to force google translate language to japanese on intial load
  // only if they already dont have the nxd-translate cookie
  if (!language_switch_enabled) {
    if (is_jpn) {
      setCookie('nxd-translate', 'true', 365, true);
      setCookie('googtrans', '/en/ja', 365, true);

      // refresh so the language would activate
      window.location.reload();
    }
  }
}

function removeSelections(evt) {
  evt.stopPropagation();
  evt.preventDefault();
  if (document.selection && document.selection.empty) {
    document.selection.empty();
  } else if (window.getSelection) {
    let sel = window.getSelection();
    sel.removeAllRanges();
  }
}

function parseTrackerInput(input) {
  if (!input) return [];

  let output = [];

  const items = input.split('\n');
  for (const item of items) {
    const clean = item.trim();
    if (clean === '') continue;

    if (isURL(clean)) {
      output.push(clean);
    } else {
      output = output.concat(parseTrackers(clean));
    }
  }

  return output;
}

function parseTrackers(trackerInput) {
  const srcGrabber = new RegExp('(?:(?:src|href)=["“”\'])([^"“”\']+)', 'ig');
  const urlGrabber = new RegExp('https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:;%_+.~#?&//=]*)', 'ig');

  const isHTMLTag = str => Boolean((str || '').match(srcGrabber));
  const hasURLs = str => Boolean((str || '').match(urlGrabber));

  let output = [];
  let z;
  let counter = 0;
  // Check if we know how to get from html
  if (isHTMLTag(trackerInput)) {
    // remove noscript fields, anyways we dont support them :O
    trackerInput = trackerInput.replace(/<noscript>(?:.|\n)+<\/noscript>/gi, '');

    // grab sources to use in the tracking
    // counter to prevent stack overflow
    while (counter < 30 && null != (z = srcGrabber.exec(trackerInput))) {
      if (z[1]) output.push(z[1]);
      counter++;
    }
  }
  // Sometimes the crappy input has URLs somewhere after explanatory materials...
  // Let's find them
  else if (hasURLs(trackerInput)) {
    // grab sources to use in the tracking
    // counter to prevent stack overflow
    while (counter < 30 && null != (z = urlGrabber.exec(trackerInput))) {
      if (z[1]) output.push(z[1]);
      counter++;
    }
  }
  // Just give back as an array the input
  else {
    output = [trackerInput];
  }

  // make sure all strings are URLs
  output.forEach((string, index) => {
    output[index] = validateURL(string);
  });

  return output;
}

function validateURL(url) {
  if (!url) return url;

  url = url.trim();
  if (url !== '' && !isURL(url)) {
    return `https://${url}`;
  }
  return url;
}

function isURL(string) {
  try {
    const url = new URL(string.trim());
    return url.protocol === 'http:' || url.protocol === 'https:';
  } catch (_) {
    return false;
  }
}

function validatePhone(phone) {
  // remove all letters
  return phone.replace(/[a-zA-Z]/gim, '').trim();
}

function validateEmail(email) {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

function getFilterParams(filters = {}) {
  if (!Object.keys(filters).length) return null;

  let result = [];

  for (const filter_name in filters) {
    const _filters = filters[filter_name];

    if (!Array.isArray(_filters)) continue;

    if (_filters.includes('all')) {
      _filters.splice(_filters.indexOf('all'), 1);
    }

    if (!_filters.length) continue;

    let _filter = [];

    for (const filter of _filters) {
      _filter.push(filter);
    }

    result.push(`{${filter_name}:${_filter.join(',')}}`);
  }

  if (!result.length) return null;
  return result.join(',');
}

function addFiltersToPath(path, options = {}) {
  let params = new URLSearchParams();
  let filters = {};

  for (const key in options) {
    const value = options[key];
    if (value == null || value === '') continue;

    // can be creatives.keywords or campaigns.keywords
    if (key.includes('keywords')) {
      params.set('keywords', options[key]);
      continue;
    }

    // can be campaigns.advertiser
    if (key.includes('advertisers')) {
      params.set('advertisers', options[key]);
      continue;
    }

    if (key === 'order' || key === 'sort' || key === 'search') {
      params.set(key, options[key]);
      continue;
    }

    if (key === 'limit' || key === 'offset') {
      params.set(key, options[key]);
      continue;
    }

    if (key === 'scope' || key === 'id') {
      params.set(key, options[key]);
      continue;
    }

    // rest go to filters
    filters[key] = value;
  }

  const filter_params = getFilterParams(filters);
  if (filter_params != null) {
    params.set('filters', filter_params);
  }

  path += '?' + params.toString();

  return path;
}

function appendScript(src, version = null) {
  let script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  if (version) {
    src += `?v=${version}`;
    script.setAttribute('data-version', version);
  }
  script.src = src;
  document.head.appendChild(script);
  return script;
}

function isMyScriptLoaded(url = null) {
  if (url == null) return true;
  for (const script of document.getElementsByTagName('script')) {
    if (script.src === url) return true;
  }
  return false;
}

function loadGooglePlaces() {
  const URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places&callback=googleAPILoaded`;

  if (isMyScriptLoaded(URL)) return;

  return new Promise(resolve => {
    window.googleAPILoaded = () => resolve();
    appendScript(URL);
  });
}

function searchLongStringsAndBreak(string, num_of_chars = 30) {
  const match = string.match(new RegExp('.{1,' + num_of_chars + '}', 'g'));

  if (match != null && match.length > 1) {
    return match.join('\u200B');
  }

  return string;
}

function breakLongStrings(string, flags = 'gim') {
  const regexp = new RegExp('(/|_)', flags);
  return string.replace(regexp, '$1\u200B');
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function loadEngageBay() {
  if (process.env.VUE_APP_ENV === 'prd') {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML =
      "var EhAPI = EhAPI || {}; EhAPI.after_load = function(){ EhAPI.set_account('1t9q618pqpkich4plu63iceflr', 'nexd'); EhAPI.execute('rules');};(function(d,s,f) { var sc=document.createElement(s);sc.type='text/javascript'; sc.async=true;sc.src=f;var m=document.getElementsByTagName(s)[0]; m.parentNode.insertBefore(sc,m); })(document, 'script', '//cdn4.engagebay.com/jsapi/ehform.js');";
    document.head.appendChild(script);
  }
}

function setClipboard(value, notification = false) {
  return new Promise((resolve, reject) => {
    function notify() {
      if (notification) {
        NotificationService.add('success', 'Copied to clipboard');
      }
    }

    // fallback for older browser or ones that does not support clipboard functionality
    function copy() {
      try {
        copyToClipboard(value);
        notify();
        resolve(value);
      } catch (error) {
        reject(error);
      }
    }

    // if clipboard funcionality exists, try that first
    if (navigator?.clipboard?.writeText) {
      // first try copying using navigator writetext
      navigator.clipboard
        .writeText(value)
        .then(_ => {
          // all good
          notify();
          resolve(value);
        })
        .catch(_ => {
          // probably a permission error, use fallback
          copy();
        });
    } else {
      // if navigator clipboard functionality is missing, use fallback
      copy();
    }
  });
}

function getURLForEnv(url) {
  if (url == null) return;

  if (process.env.NODE_ENV === 'development') {
    if (url.includes('.dev/')) {
      return url.replace('.dev/', `.dev:8081/#/`);
    } else if (url.startsWith('/')) {
      return `/#${url}`;
    }
  }

  return url;
}

function truncateString(string, max_length = 20) {
  return string.length > max_length ? string.slice(0, max_length - 1) + '...' : string;
}

async function loadHotjarTracking(user) {
  window.hj =
    window.hj ||
    function () {
      (window.hj.q = window.hj.q || []).push(arguments);
    };
  window._hjSettings = { hjid: 3372187, hjsv: 6 };
  const URL = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
  const script = appendScript(URL);
  script.onload = _ => {
    window.hj('identify', user.user_id, {
      email: user.email,
      name: user.name,
      company: user.group?.name ?? 'no company',
      signed_up: user.created_on,
    });
  };
}

function trimQuery(query) {
  query = query ?? '';
  return query.trim().toLowerCase();
}

function regexEscape(string) {
  return string.replace(/(\[|\]|\(|\)|\{|\}|\$|\.|\+|\^|\+|\||\\|\?|\*)/gm, '\\$1');
}

function matchQuery(string, query) {
  string = trimQuery(string);
  query = trimQuery(query);

  // try matching lowercase and trimmed values before regex matching
  if (string.includes(query)) return true;

  // try to see if it matches with regex
  query = regexEscape(query);
  return string.search(query) !== -1;
}

async function awaitLibrary(library, error, callback) {
  const max = 10;
  let time = 0;

  while (time < max) {
    if (window[library] == null) {
      time++;
      await sleep(1000);
    } else {
      return callback();
    }
  }

  throw new Error(error);
}

async function initGoogleAnalytics() {
  if (process.env.VUE_APP_ENV !== 'prd') return;

  const dataLayer = 'dataLayer';
  window[dataLayer] = window[dataLayer] ?? [];
  window[dataLayer].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  });

  const URL = `https://www.googletagmanager.com/gtm.js?id=GTM-5CCJG4DH&l=${dataLayer}`;
  appendScript(URL);
}

function sortUsersByClosestQueryMatch(users, query) {
  query = trimQuery(query ?? '');
  users = users ?? [];

  return users.sort((a, b) => {
    const aIndex = a.name.toLowerCase().indexOf(query.toLowerCase());
    const bIndex = b.name.toLowerCase().indexOf(query.toLowerCase());

    if (aIndex === bIndex) {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    }

    return bIndex - aIndex;
  });
}

function getRoles() {
  let output = {};
  for (const [key, value] of Object.entries(USER_ROLE)) {
    if (value === USER_ROLE.INSTREAM_EXPERT) {
      continue;
    }
    output[value] = key.replace('_', ' ');
  }
  return output;
}

function serializeAssetName(filename) {
  if (filename == null || typeof filename !== 'string') {
    return filename;
  }

  // mock how how backend converts and renames asset
  return filename
    .trim()
    .replace(/\s/g, '-')
    .replace(/\.jpeg$/i, '.jpg')
    .replace(/\.([^.]+)?$/, s => s.toLowerCase());
}

function loadUserGuiding(user) {
  if (process.env.VUE_APP_ENV !== 'prd') return;

  const LAYER = 'userGuidingLayer';
  const USER_GUIDING = 'userGuiding';
  window[LAYER] = window[LAYER] ?? [];

  const URL = 'https://static.userguiding.com/media/user-guiding-KN376082TRDID-embedded.js';
  const script = appendScript(URL);

  script.onload = _ => {
    if (window[USER_GUIDING]) {
      return;
    }
    let ug = (window[USER_GUIDING] = { q: [] });
    ug.c = function (n) {
      return function () {
        ug.q.push([n, arguments]);
      };
    };
    for (const method of ['previewGuide', 'finishPreview', 'track', 'identify', 'hideChecklist', 'launchChecklist']) {
      ug[method] = ug.c(method);
    }
    window[USER_GUIDING]?.identify?.(user.user_id);
  };
}

function getFoldersDepth(breadcrumbs = [], type = FOLDER.CAMPAIGN) {
  if (breadcrumbs == null) return 0;

  let count = 0;

  for (const breadcrumb of breadcrumbs) {
    if (breadcrumb?.breadcrumbs?.constructor === Array) {
      for (const _breadcrumb of breadcrumb.breadcrumbs) {
        if (_breadcrumb?.meta?.folder_type === type) count++;
      }
      continue;
    }

    if (breadcrumb?.meta?.folder_type === type) count++;
  }

  return count;
}

function sortBy(array, keySelector) {
  return array.sort((a, b) => {
    const keyA = keySelector(a)?.toLowerCase();
    const keyB = keySelector(b)?.toLowerCase();

    if (keyA < keyB) return -1;
    if (keyA > keyB) return 1;
    return 0;
  });
}

function search(array, selector, query) {
  const q = (query ?? '').trim().toLowerCase().split(' ').filter(Boolean);

  if (!q.length) return array;

  return array.filter(obj => {
    let match = 0;
    for (let i = 0; i < q.length; i++) {
      if (selector(obj)?.toLowerCase()?.includes(q[i])) {
        match++;
      }
    }
    return match > q.length / 2;
  });
}

function getDateFormats() {
  const formats = [];
  for (const format of DATE_FORMAT_OPTIONS) {
    formats.push({ value: format, label: format, description: moment().format(format.toUpperCase()) });
  }
  return formats;
}

function slugify(string) {
  return (string ?? '')
    .toString()
    .replace(/[\u0300-\u036f]/g, '') // https://en.wikipedia.org/wiki/List_of_Unicode_characters#Combining_marks
    .toLowerCase()
    .trim()
    .replace(/[^-a-z0-9 ]/g, '') // exclude a-z, 0-9, space and dash
    .replace(/\s+/g, '-');
}

export {
  round,
  clone,
  getCookie,
  setCookie,
  clearCookie,
  getBase64,
  saveJSON,
  saveCSV,
  s2ab,
  downloadFile,
  downloadBlob,
  getFileContents,
  b64toBlob,
  dataURLtoFile,
  unique,
  checkForLocale,
  removeSelections,
  parseTrackerInput,
  parseTrackers,
  validateURL,
  isURL,
  validatePhone,
  validateEmail,
  addFiltersToPath,
  loadGooglePlaces,
  searchLongStringsAndBreak,
  breakLongStrings,
  sleep,
  loadEngageBay,
  setClipboard,
  trimQuery,
  getURLForEnv,
  truncateString,
  loadHotjarTracking,
  regexEscape,
  matchQuery,
  awaitLibrary,
  initGoogleAnalytics,
  sortUsersByClosestQueryMatch,
  getRoles,
  serializeAssetName,
  loadUserGuiding,
  getFoldersDepth,
  sortBy,
  appendScript,
  search,
  getDateFormats,
  slugify,
};
