// constants need to be in sync with the api/globals/app.php
const STORAGE_KEY = 'appconf';

let ready;
exports.ready = cb => {
  ready = cb;
};

exports.init = () => {
  try {
    const CONF = window.localStorage.getItem(STORAGE_KEY);
    if (!CONF) throw new Error('No saved configuration');
    exports.setup(JSON.parse(CONF));
  } catch (e) {}
};

exports.setup = CONF => {
  try {
    for (const constant in CONF) {
      if (typeof CONF[constant] === 'string' || Array.isArray(CONF[constant])) {
        exports[constant] = CONF[constant];
      } else {
        exports[constant] = Object.assign(exports?.[constant] ?? {}, CONF[constant]);
      }
    }
    if (ready != null) {
      ready();
    }
  } catch (e) {
    // make sure we get any const errors
    window._bugsnag.notify(e);
  }

  try {
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(CONF));
  } catch {
    /** safari can deny writing in localStorage */
  }
};

exports.FEEDBACK = {};
exports.PUBLISH_ERROR = {};
exports.DEVICE = {};
exports.TYPE = {};
exports.TYPES = {};
exports.STATUS = {};
exports.STATUSES = {};
exports.MIME_TYPES = {};
exports.EXPORT_TYPES = {};
exports.USER_ROLE = {};
exports.ROLE_NAME = {};
exports.ROLE_HIERARCHY = [];
exports.DEVICE_STRINGS = {};
exports.TYPE_STRINGS = {};
exports.STATUS_STRINGS = {};
exports.CREATIVE_CORE = {};
exports.ASSET = {};
exports.GLOBAL_ASSET = {};
exports.BREADCRUMB = {};
exports.LISTITEM = {};
exports.FOLDER = {};
exports.ANALYTICS_EXPORT = {};
exports.ANALYTICS_EXPORT_RANGE = {};
exports.ANALYTICS_EXPORT_INTERVAL = {};
exports.PERMISSION = {};
exports.USER_FLAGS = {};
exports.TIMELINE = {
  ANIMATION: {},
  EASING: {},
  PROPERTY: {},
  DIRECTION: {},
};
exports.MODULES = {};
exports.SEARCH = {};
exports.ASSET_LIBRARY = {};
exports.CURRENCY = {};
exports.IMPRESSION_TYPES = {};
exports.DEFAULT_DATE_FORMAT = 'yyyy-mm-dd';
exports.DATE_FORMAT_OPTIONS = [exports.DEFAULT_DATE_FORMAT];
exports.TEMPLATE_FEATURE = {};

exports.SIZE = {
  DEFAULT: {
    RESPONSIVE: {
      WIDTH: 640,
      HEIGHT: 640,
    },
    VAST: {
      WIDTH: 1280,
      HEIGHT: 720,
    },
    SKIN: {
      WIDTH: 1920,
      HEIGHT: 1080,
    },
    HTML: {
      WIDTH: 300,
      HEIGHT: 250,
    },
  },

  INTERSTITIAL: {
    WIDTH: 640,
    HEIGHT: 1140,
    HEIGHT_HIGH_ASPECT: 1280,
  },

  // FW sizes for interstitial creative
  FW: {
    INTERSTITIAL: {
      WIDTH: 480,
      HEIGHT: 854,
      HEIGHT_HIGH_ASPECT: 960,
    },
  },

  CTA: 56,

  CREATIVE_VALIDATION_MIN: 50,
  CREATIVE_VALIDATION: 1920,

  // 4k resolution, our video max 1920 @x2
  MAX_FILE_DIMENSION: 3840,
};

exports.DSP = {
  GOOGLEADS: 'googleads',
};

exports.STATUS_CLASS = {
  DRAFT: 'status-draft',
  PENDING: 'status-pending',
  PAUSED: 'status-paused',
  LIVE: 'status-live',
  FINISHED: 'status-finished',
  ARCHIVED: 'status-inactive',
  GOOGLE_ADS: `status-${exports.DSP.GOOGLEADS}`,
};

const ONE_MINUTE = 60000;
exports.TIMEOUT = {
  ONE_MINUTE,
  FIVE_MINUTES: 5 * ONE_MINUTE,
  THIRTY_MINUTES: 30 * ONE_MINUTE,
};

exports.TEMPLATE = {
  VAST_DEFAULT: 'qsfpBY',
  SOCIAL_DEFAULT: 'OMWj6CCb',
  DYN_END_INFEED_DEFAULT: 'jHRjyS',
  DYN_END_INTERSTITIAL_DEFAULT: 'kcxkge',
};

exports.VIEW = {
  CAMPAIGNS: 'campaigns',
  CAMPAIGNS_USER: 'campaigns_user',
  CAMPAIGNS_FOLDERS: 'folder_campaigns',
  CREATIVES: 'creatives',
  CREATIVES_FOLDERS: 'folder_creatives',
  CREATIVE: 'creative',
  CREATIVE_FOLDER: 'folder_creative',
  SEARCH: 'search',
  FLIGHT: 'flight',
  PROFILE: 'profile',
  BILLING: 'billing',
  ORGANIZATION: 'organization',
  ANALYTICS: 'analytics',
  LOGIN: 'login',
  REGISTER: 'register',
  REGISTER_COMPLETED: 'register-completed',
  INVITE: 'invite',
  FORGOT: 'forgot',
  RESET: 'reset',
  ADMIN: {
    DASHBOARD: 'Admin',
    USERS: 'Users',
    USER: 'User',
    GROUPS: 'Groups',
    GROUP: 'Group',
    INVITES: 'Invites',
    INVITE: 'Invite',
    NEW_INVITE: 'New Invite',
    AD_SUPPORT: 'Ad Support',
    CREATIVE_CHANGELOG: 'Creative Changelog',
    TEMPLATES: 'Templates',
    TEMPLATE: 'Template',
    WHITELABELS: 'Whitelabels',
    WHITELABEL: 'Whitelabel',
    SUBSCRIPTION_PLAN: 'Subscription Plan',
    SUBSCRIPTION_PLANS: 'Subscription Plans',
    SUBSCRIPTION_FEATURE: 'Subscription Feature',
    SUBSCRIPTION_FEATURES: 'Subscription Features',
    DANGER: 'DANGER',
  },
  TEST: 'components',
  GROUP_ASSETS: 'group_assets',
  GROUP_ASSETS_FOLDER: 'group_assets_folder',
  DASHBOARD: 'dashboard',
  AD_CREATOR: 'ad_creator',
  SDK_CREATE: 'sdk_create',
  PREVIEW: {
    CREATIVES_CAMPAIGN: 'campaign_creatives',
    CREATIVES_FOLDER: 'folder_creatives',
    CAMPAIGN: 'campaign',
    FOLDER: 'folder',
    // @deprecated by SD-7644 / shortener URL / hash views
    LIST: 'list',
    // @deprecated by SD-7644 / shortener URL / hash views
    CREATIVES_LIST: 'list_creatives',
    HASH_LIST: 'hash_list',
    HASH_CREATIVES_LIST: 'hash_list_creatives',
    CREATIVE: 'creative',
    LANDING: 'landing',
    CLICKURL: 'CLICKURL',
  },
  DUPLICATE: 'duplicate',
};

exports.APPS = {
  CM: 'app',
  DEVELOPER: 'devtool',
  OPTIMIZER: 'optimizer',
  PREVIEW: 'preview',
  ADMIN: 'admin',
  ADSPECS: 'adspecs',
};

exports.KEYS = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  COMMA: ',',
  SPACE: ' ',
};

exports.FLIGHT = {
  KEYS: {
    KEY: 'key',
    OP: 'op',
    VALUE: 'value',
  },

  TYPES: {
    LOCATION: 'location',
    ZIP: 'postalcode',
    LANGUAGE: 'language',
    DATE: 'date',
    TIME: 'time',
  },

  SEPARATORS: {
    AND: 'and',
    OR: 'or',
  },

  OPERATORS: {
    IN: 'is',
    'NOT IN': 'is not',
  },

  EXPORT_TYPES: {
    INS: 'ins',
    XML: 'xml',
  },

  LOADING: {
    STATEMENT: 'statement',
    CONDITION: 'condition',
  },
};

exports.IMPORT = {
  VAST: 'import-vast',
  QUANTUM: 'import-quantum',
  HTML5: 'import-html5',
  EXCEL: 'import-excel',
  SOCIAL: 'import-social',
};

exports.FIXED_ELEMENT = {
  BANNER: 'navbar-banner',
  SECONDARY_NAVBAR: 'secondary-navbar',
  BREADCRUMB: 'breadcrumb',
  LIST_HEADER: 'list-header',
  CONTENT: 'content',
  STICKY_CONTENT: 'sticky-content',
};

// direct define of status codes instead of using /ui/conf, because status codes are used in http requests
exports.HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

exports.HTTP_CODE = {
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  EARLY_HINTS: 103,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  BANDWIDTH_LIMIT_EXCEEDED: 509,
};

exports.SUBSCRIPTION = {
  BASIC: 'aqUnXzA4iwUx',
  SOLO: 'BwEAbYXNIvyC',
  TEAM: 'eTJdZcJTvCXl',
  ENTERPRISE: 'OuK4HirThi3P',
  INTEGRATION: 'DoXw78DtAKOB',
  PAYASYOUGO: 'tXOP0ddX72BE',
};

exports.ADMIN_TOGGLE = 'admin.toggle';

exports.USER_DASHBOARD = {
  STORAGE_KEY: 'user_dashboard.sort',
  AZ: 'az',
  ZA: 'za',
  OLDEST: 'oldest',
};

exports.CTA_OPTIONS = {
  url: 'Link',
  call: 'Call',
  sms: 'SMS',
  calendar: 'Calendar',
  map: 'Map',
  lightbox: 'Lightbox',
  mailto: 'Email',
};

exports.CUSTOM_ASSET = {
  ARROW_LEFT: 'custom_arrow_left',
  ARROW_RIGHT: 'custom_arrow_right',
  CLOSE: 'custom_close',
  BRUSH: 'custom_brush',
  LIGHTBOX_CLOSE: 'custom_close_lightbox',
};
