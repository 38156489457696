import NotificationService from '@master/Services/NotificationService';
import SaveService from '@master/Services/SaveService';
import { s2ab, downloadBlob, getFileContents } from '@helpers/Global';
import { VIEW, HTTP_CODE, HTTP_METHOD, APPS } from '@master/constants';
import { alert } from '@libs/alerts';
import SDKPlugin from '@libs/SDKPlugin';

const HOOK_START = 'start';
const HOOK_UPDATE = 'update';
const HOOK_END = 'end';

class RequestService {
  #status = 0;

  #path = null;
  #method = HTTP_METHOD.GET;
  #data = {};
  #options = {};
  #token = null;
  #accept = 'application/json';

  #hooks = {
    [HOOK_START]: [],
    [HOOK_UPDATE]: [],
    [HOOK_END]: [],
  };

  #getHeaders() {
    const headers = new Headers({
      Accept: this.#accept,
    });

    if (this.#token !== null && this.#options.auth === true) {
      headers.set('Authorization', `Bearer ${this._token}`);
      headers.set('X-Requested-With', `XMLHttpRequest`);
    }

    // sdk key and client id are required for all requests
    // this is a way to pass them to the server
    // if they are not present, the server will use our Auth service to handle user
    SDKPlugin.setHeaders(headers);

    // get request doesn't need any content-type header, since it wont send any data
    // also if accessing to CDN files as countries.json or timezone.json, this is not an accepted header
    if (this.#method !== HTTP_METHOD.GET) {
      headers.set('Content-Type', `application/json; charset=urf-8`);
    }

    return headers;
  }

  acceptAny() {
    this.#accept = '*/*';
  }

  setPath(path) {
    this.#path = path;
  }

  setMethod(method) {
    this.#method = method;
  }

  setData(data) {
    this.#data = data;
  }

  setOptions(options) {
    this.#options = options;
  }

  setToken(token) {
    this.#token = token;
  }

  hook(hook, callback) {
    if (this.#hooks[hook] != null) {
      this.#hooks[hook].push(callback);
    }
  }

  #sendHooks(hook) {
    if (this.#hooks[hook] != null) {
      this.#hooks[hook].forEach(callback => {
        callback();
      });
    }
  }

  #handleNotification(response) {
    if (response.msg == null) return;

    // default, also for status code 200 / success
    let type = 'primary';
    let notificationFunction = null;

    if (this.#status >= HTTP_CODE.BAD_REQUEST) {
      type = 'danger';
    } else if (this.#status >= HTTP_CODE.MOVED_PERMANENTLY) {
      type = 'warning';
    } else if (this.#status === HTTP_CODE.CREATED) {
      type = 'success';
    }

    if (typeof this.#options.notification === 'function') {
      notificationFunction = (type, msg) => {
        this.#options.notification(type, msg);
      };
    } else if (this.#options.notification === true || this.#options.notification === type || (this.#status === HTTP_CODE.OK && this.#options.notification === 'success')) {
      notificationFunction = (type, msg) => {
        NotificationService.add(type, msg);
      };
    }

    if (notificationFunction != null) {
      notificationFunction(type, response.msg);
    }
  }

  #handleFile(result) {
    // handle file
    const bin = getFileContents(result.file);
    const ab = s2ab(bin);
    const blob = new Blob([ab], { type: result.file.type });
    downloadBlob(result.file.filename, blob);
  }

  #handleMultipleFiles(result) {
    // handle multiple files (campaign export as csv, with multiple creatives)
    for (const file of result.files) {
      if (file.download) {
        this.#handleFile({ file });
      }
    }
  }

  #handleRedirect(result) {
    if (this.#options.allow_redirects && result.redirect_url) {
      window.location.href = result.redirect_url;
      return true;
    }
    return false;
  }

  #handleErrorsList(response) {
    let messages = [];
    for (const error of response.errors_list) {
      // dont show same error twice
      if (messages.indexOf(error.msg) === -1) {
        NotificationService.add('danger', error.msg);
        messages.push(error.msg);
      }
    }
  }

  #onSuccess(response, resolve) {
    const result = response.result ?? null;

    if (result != null) {
      if (this.#handleRedirect(result)) {
        return;
      }

      // only handle file download when content type is not json
      if (result.file != null && result.file.download === true) {
        this.#handleFile(result);
        return resolve(result);
      }

      if (result.files != null) {
        this.#handleMultipleFiles(result);
        return resolve(result);
      }

      this.#handleNotification(response);
      return resolve(result);
    }

    if (response.errors_list?.length > 0) {
      this.#handleErrorsList(response);
      return reject(response);
    }

    // Would like to show notification messages even if result is null.
    this.#handleNotification(response);

    // custom api calls response that has no .result (eg country json get);
    return resolve(response);
  }

  #onError(response, executor) {
    if (this.#status === HTTP_CODE.UNAUTHORIZED) {
      // api calls that should not refresh/redirect to login when response is 401
      const non_refreshable_401s = ['auth/me', 'v2/user/me', 'user/login'];

      const found = non_refreshable_401s.some(endpoint => this.#path.includes(endpoint));
      if (!found) {
        return location.reload();
      }
    }

    if (this.#status === HTTP_CODE.REQUEST_TIMEOUT) {
      // async processing retry
      return executor({
        message: response.error,
        code: this.#status,
        response,
      });
    }

    if (response.msg != null) {
      this.#handleNotification(response);
      return executor({ message: response.msg, code: this.#status, response });
    }

    if (response.error != null && typeof response.error === 'string') {
      return executor({
        message: response.error,
        code: this.#status,
        response,
      });
    }

    return executor({ message: 'unknown error', code: this.#status, response });
  }

  send() {
    return new Promise((resolve, reject) => {
      this.#sendHooks(HOOK_START);

      const options = {
        method: this.#method,
        headers: this.#getHeaders(),
        mode: 'cors',
        credentials: this.#options.withCredentials ? 'include' : 'same-origin',
      };

      if (this.#method !== HTTP_METHOD.GET) {
        options.body = JSON.stringify(this.#data);
      }

      fetch(new Request(this.#path, options))
        .then(response => {
          this.#status = response.status;

          if (response.ok) {
            this.#sendHooks(HOOK_UPDATE);
            const content_type = response.headers.get('Content-Type');
            if (this.#options.raw) {
              return response.text();
            }
            if (content_type.indexOf('image') !== -1) {
              return response.blob();
            }
          }
          return response.json();
        })
        .then(response => {
          if (this.#status >= HTTP_CODE.OK && this.#status < HTTP_CODE.BAD_REQUEST) {
            const warnings = response.warnings ?? response.errors_list ?? [];

            // moslt for bulk actions, the response is success and warnings and/or errors are provided when needed
            if (warnings?.length > 0) {
              const warnings = response.warnings ?? response.errors_list ?? null;
              alert(undefined, `<div>${warnings.map(warning => `<div>${warning.msg}</div>`).join('')}</div>`);
              return this.#onSuccess(response, resolve);
            }
            return this.#onSuccess(response, resolve);
          }

          return this.#onError(response, reject);
        })
        .catch(error => {
          if (error instanceof Error) {
            const message = error.message.toLowerCase();
            // ignore fetch errors, seems to happen when some request is canceled via navigation etc
            if (message === 'failed to fetch' || message === 'load failed') {
              return;
            }
          }

          if (typeof window?._bugsnag?.notify === 'function') {
            window._bugsnag.notify(error);
          }

          let fatal_msg = 'System failure. Please contact support@nexd.com.';

          if ([APPS.PREVIEW, APPS.ADSPECS].includes(process.env.VUE_APP_APPID)) {
            fatal_msg = 'System failure. Please try again.';
          }

          this.#status = HTTP_CODE.INTERNAL_SERVER_ERROR;
          this.#onError({ msg: fatal_msg, code: HTTP_CODE.INTERNAL_SERVER_ERROR, error }, reject);
        })
        .finally(_ => {
          this.#sendHooks(HOOK_END);
        });
    });
  }
}

const $http = {
  _endpoint: 'https://api.nexd.com/',
  _token: null,
  _router: null,

  setEndpoint(endpoint) {
    this._endpoint = endpoint;
  },

  getEndpoint() {
    return this._endpoint;
  },

  setToken(token) {
    this._token = token;
  },

  getToken() {
    return this._token;
  },

  setRouter(router) {
    this._router = router;
  },

  /**
   * @param {string} url - url or API path of the request, if begins with http, that will be called directly not nexd API
   * @param {string} method - request method: GET, POST, PUT, DELTE
   * @param {object} data - data object to be sent
   * @param {object} options - options, see validateOptions method
   * @returns
   */
  makeRequest(url, method, data = {}, options = null) {
    const request = new RequestService();
    if (!url.startsWith('https://')) {
      url = this._endpoint + url;
    } else {
      // since the path is not our API, add accept any header
      request.acceptAny();
    }

    request.setPath(url);
    request.setMethod(method);
    request.setData(data);
    options = this.validateOptions(options);

    request.setOptions(options);
    request.setToken(this.getToken());

    const handle_creative_save = options?.creative && method !== HTTP_METHOD.GET && /creatives?\/[^\/]+/.test(url);
    const handle_flight_save = options?.flight && (method === HTTP_METHOD.POST || method === HTTP_METHOD.PUT) && /flights?\/[^\/]+/.test(url);

    if (handle_creative_save) {
      request.hook(HOOK_START, _ => {
        SaveService.creative.startSaving();
      });
      request.hook(HOOK_UPDATE, _ => {
        SaveService.creative.setTimestamp();
      });
      request.hook(HOOK_END, _ => {
        SaveService.creative.stopSaving();
      });
    }
    if (handle_flight_save) {
      request.hook(HOOK_START, _ => {
        SaveService.flight.startSaving();
      });
      request.hook(HOOK_UPDATE, _ => {
        SaveService.flight.setTimestamp();
      });
      request.hook(HOOK_END, _ => {
        SaveService.flight.stopSaving();
      });
    }

    return request.send();
  },

  get(url, options = null) {
    return this.makeRequest(url, HTTP_METHOD.GET, {}, options);
  },

  post(url, data = {}, options = null) {
    return this.makeRequest(url, HTTP_METHOD.POST, data, options);
  },

  put(url, data = {}, options = null) {
    return this.makeRequest(url, HTTP_METHOD.PUT, data, options);
  },

  delete(url, data = {}, options = null) {
    return this.makeRequest(url, HTTP_METHOD.DELETE, data, options);
  },

  validateOptions(options = null) {
    // default options
    const defaults = {
      // show notification on response
      notification: true,
      // set withCredentials true / false
      withCredentials: true,
      // default auth is always true
      auth: true,
      // default force writes to eu is true
      force_eu: true,
      // should it parse json or output raw body
      raw: false,
      // if dataservice should redirect the user if API has `redirect` key
      allow_redirects: false,
      // if request is a creative request, then use SaveService.creative to update saved timestamp and loading states
      creative: this._router?.history?.current?.name === VIEW.CREATIVE,
      // if request is a flight request, then use SaveService.flight to update saved timestamp and loading states
      flight: this._router?.history?.current?.name === VIEW.FLIGHT,
    };

    if (options == null) {
      return defaults;
    }

    for (let key in defaults) {
      if (options[key] == null) {
        options[key] = defaults[key];
      }
    }

    return options;
  },
};

export default $http;
