export const types = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'update',
  'media-buy',
  'link',
  'link-primary',
  'link-accent',
  'link-danger',
  'link-light',
  'dropdown',
  'quantum',
  'cyan',
  'light',
  'navbar',
  'banner',
  'filter',

  // login specific buttons
  'login-primary',
  'login-verify',
  'login-secondary',
  'login-google',
  'login-microsoft',
];
