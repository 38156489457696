import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';

export function createButton(Vue, container, resolve, propsData) {
  const action = propsData.action;
  delete propsData.action;

  const ButtonComponent = Vue.extend(Button);
  const button = new ButtonComponent({ propsData });
  button.$mount();

  button.$on('click', e => {
    e.preventDefault();
    e.stopPropagation();
    resolve(action);
    container?.close?.();
  });

  return button.$el;
}

export function createButtons(Vue, parent) {
  const ButtonsComponent = Vue.extend(Buttons);
  const buttons = new ButtonsComponent({ propsData: { align: 'right' } });
  buttons.$mount();
  parent.appendChild(buttons.$el);

  return buttons.$el;
}
